<div class="body">
  <app-search-information *ngIf="storageService.currentScenarioId &&
    storageService.searchRecordList &&
    storageService.searchRecordList[storageService.currentScenarioId] &&
    storageService.searchRecordList[storageService.currentScenarioId].length > 0 &&
    !this.storageService.isTabbedUI"
    [searchLayout]="searchLayout"
    (agentSelectedCallerInformation)="agentSelectedCallerInformation($event)"
    [searchRecordList]="storageService.searchRecordList[storageService.currentScenarioId]">
  </app-search-information>
  <app-activity *ngIf="storageService.currentScenarioId && enableCallActivity" (saveActivity)="saveActivity($event)"
    (discardActivity)="discardActivity($event)" [scenarioId]="storageService.currentScenarioId"
    [quickCommentList]="quickCommentList" [enableAutoSave]="enableAutoSave" [enableDiscard]='enableDiscard'
    [autoSaveTimer]='autoSaveTimer' [quickCommentOptionRequiredCadArray]="quickCommentOptionRequiredCadArray"
    [clickSubject]="clickSubject"
    [searchLayout]="searchLayout"
    [tabbedActivityList]="storageService.tabsObject[0]"
    (agentSelectedCallerInformation)="agentSelectedCallerInformation($event)">
  </app-activity>
  <app-recent-activities *ngIf="checkIfRecentActivitiesExist()" (saveActivity)="saveActivity($event, true)"
    (getRecentWorkItem)="getRecentWorkItem($event)" (screenpopWorkItem)="agentSelectedCallerInformation($event)"
    [quickCommentList]="quickCommentList" [isInConsoleView]="isInConsoleView">
  </app-recent-activities>
  <amc-create *ngIf="DisplayQuickCreate" [Entities]="QuickCreateEntities" (CreateNewEntity)="createNewEntity($event)">
  </amc-create>
</div>
