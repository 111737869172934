<ngb-accordion #acd="ngbAccordion" [closeOthers]="true" activeIds="toggle-1, toggle-2">
    <ngb-panel id="toggle-1" title="Create New">
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <img src="../../assets/images/QuickCreate/Create_Lead.png" ngbTooltip="lead"
                        placement="top down right" class="img-fluid" alt="...">
                </div>
                <div class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <img src="../../assets/images/QuickCreate/Create_Case.png" ngbTooltip="case"
                        placement="top down right" class="img-fluid" alt="...">
                </div>
                <div class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <img src="../../assets/images/QuickCreate/Create_Opp.png" ngbTooltip="Opportunity"
                        placement="top down right" class="img-fluid" alt="...">
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>