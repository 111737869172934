<amc-current-activity *ngIf="!this.storageService.isTabbedUI"
  [ActivityDetails]="this.storageService.activityList[this.scenarioId]" [quickCommentList]="quickCommentList"
  [isAutoSave]="enableAutoSave" [enableDiscard]="enableDiscard" [autoSaveTimer]="autoSaveTimer"
  [quickCommentOptionRequiredCadArray]="quickCommentOptionRequiredCadArray"
  [WhoObjectList]="this.storageService.whoList[this.scenarioId]"
  [WhatObjectList]="this.storageService.whatList[this.scenarioId]"
  [scenarioToCADMap]="this.storageService.scenarioToCADMap[this.scenarioId]" (EventEmitter)="getEvent($event)">
</amc-current-activity>

<ul ngbNav #nav="ngbNav" [(activeId)]="activeID" class="nav-tabs">
  <li *ngFor="let tabbedActivity of tabbedActivityList" [ngbNavItem]="tabbedActivity.scenario">
    <button ngbNavLink>
      <img [src]="tabbedActivity.iconName" style="width: 15px;">
      {{tabbedActivity.title}}
    </button>
    <ng-template ngbNavContent>
      <app-search-information *ngIf="tabbedActivity.scenario &&
      this.storageService.searchRecordList &&
       this.storageService.searchRecordList[tabbedActivity.scenario] &&
       this.storageService.searchRecordList[tabbedActivity.scenario].length > 0" [searchLayout]="searchLayout"
        (agentSelectedCallerInformation)="onAgentSelectedCallerInformation($event)"
        [searchRecordList]="this.storageService.searchRecordList[tabbedActivity.scenario]">
      </app-search-information>
      <amc-current-activity [ActivityDetails]="tabbedActivity.ActivityDetails" [quickCommentList]="quickCommentList"
        [isAutoSave]="enableAutoSave" [enableDiscard]="enableDiscard"
        [quickCommentOptionRequiredCadArray]="quickCommentOptionRequiredCadArray"
        [WhoObjectList]="tabbedActivity.WhoObjectList" [WhatObjectList]="tabbedActivity.WhatObjectList"
        [scenarioToCADMap]="tabbedActivity.scenarioToCADMap" (EventEmitter)="getEvent($event)">
      </amc-current-activity>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2" *ngIf="this.storageService.isTabbedUI"></div>