<div *ngIf="isCreateMaximized" class="callDisplay">
  <div class="editor callHeader">
    <label class="headerLabel">
      <b>Create New</b>
    </label>
    <img
      class="ViewResizeImage"
      src="assets/images/section_collapse.png"
      (click)="isCreateMaximized = false"
      title="Collapse"
    />
  </div>
  <div class="callBody">
    <div *ngIf="Entities" class="container">
      <div
        *ngFor="let entity of getEntities()"
        class="createNewLabel"
        (click)="createNewEntity(entity)"
        title="{{ getDisplay(Entities[entity]) }}"
      >
        <img
          class="createEntityImages"
          src="{{ getImage(Entities[entity]) }}"
        />
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isCreateMaximized" class="callDisplay">
  <div class="editor callHeader">
    <label class="headerLabel">
      <b>Create New</b>
    </label>
    <img
      class="ViewResizeImage"
      src="assets/images/section_expand.png"
      (click)="isCreateMaximized = true"
      title="Expand"
    />
  </div>
</div>
