<!-- <app-recent-activity
  [isInConsoleView]="isInConsoleView"
  [quickCommentList]="quickCommentList"
  [workingScenarioID]="this.storageService.workingRecentScenarioId"
  (EventEmitter)="getEvent($event)"
  [RecentActivityList]="getRecentScenarios()"
>
</app-recent-activity> -->
<div class="callSection">
  <div *ngIf="collapseToggle" class="callDisplay">
    <div class="editor callHeader">
      <label class="headerLabel">
        <b>Recent Activities</b>
      </label>
      <img class="ViewResizeImage" src="../../assets/images/section_collapse.png" title="Collapse" (click)="expandAndCollapse(false)" />
    </div>
    <div class="callBodyBorderless">
      <div *ngFor="let recentScenarioId of storageService.recentScenarioIdList; let last = last; let idx=index; trackBy:trackByFn">
        <amc-recent-activity
          [ActivityDetails]="this.storageService.activityList[recentScenarioId]"
          [isInConsoleView]="isInConsoleView"
          [quickCommentList]="quickCommentList"       
          [workingScenarioID]="this.storageService.workingRecentScenarioId"
          [idx]="idx"
          [last]="last"
          [WhoObjectList]="this.storageService.whoList[recentScenarioId]"
          [WhatObjectList]="this.storageService.whatList[recentScenarioId]"
          (EventEmitter)="getEvent($event)"
        >
        </amc-recent-activity>
      </div>
    </div>
  </div>
  <div *ngIf="!collapseToggle" class="callDisplay">
    <div class="editor callHeader">
      <label class="headerLabel">
        <b>Recent Activities</b>
      </label>
      <img class="ViewResizeImage" src="../../assets/images/section_expand.png" title="Expand" (click)="expandAndCollapse(true)" />
    </div>
  </div>
</div>

